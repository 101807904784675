import React, { FC, useEffect } from 'react'

import { graphql } from 'gatsby'
import { Box, Grid } from '@material-ui/core'
import { SanityModuleImageTwoColumns } from 'web/graphql-types'
import SanityImage from '@/atoms/SanityImage'

const ImageTwoColumnsModule: FC<SanityModuleImageTwoColumns> = (props) => {

  const { doubleImageArray } = props

  return (
  <Box p={{xs: '0 3rem 3rem 3rem', sm: '0 6rem'}}  mb={{ xs: 0, sm: 3 }} mx="auto" maxWidth="1440px">
    <Grid alignItems="center" container direction="row" spacing={0}>
      {doubleImageArray?.map(img => {
        return img ? (
          <Grid item xs={12} sm={6}>
            <Box px={{ xs: 0, sm: 5 }} my={{ xs: 3, sm: 0 }}>
              <SanityImage
                image={img}
                altText={img.asset?.altText || ''}
                width={600}
              />
            </Box>
          </Grid>
        ) : null
      })}
    </Grid>
  </Box>
)}

export default ImageTwoColumnsModule

export const query = graphql`
  fragment moduleImageTwoColumnsData on SanityModuleImageTwoColumns {
    _key
    _type
    doubleImageArray: image {
      ...Image
    }
  }
`
