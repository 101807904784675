import React, { FC, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { theme } from '@/styles/theme'
import { Typography, Box } from '@material-ui/core'
import SanityImage from '@/atoms/SanityImage'
import useInterval from '@/hooks/useInterval'
import { useWindowDimensions } from '@/hooks/useWindow'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { SanityModuleHeroSlider } from 'web/graphql-types'

const StyledBox = styled(Box)`
  height: calc(100vh - 2rem);
  @media screen and (max-width: 1024px) {
    height: calc(100vh - 3rem);
  }
`

const HeroSlider: FC<SanityModuleHeroSlider> = props => {
  const { heroSliderItems } = props
  const numberOfItems = heroSliderItems?.length || 0
  const [carouselIndex, setCarouselIndex] = useState(0)
  const [delay, setDelay] = useState(0)
  const { width } = useWindowDimensions()

  const onSlideSwitch = async () => {
    setSlideIndex()
  }

  useInterval(onSlideSwitch, delay)

  useEffect(() => {
    setDelay(heroSliderItems?.[0]?.interval || 0)
  }, [])

  const setSlideIndex = () => {
    carouselIndex < numberOfItems - 1
      ? setCarouselIndex(carouselIndex + 1)
      : setCarouselIndex(0)
  }

  useEffect(() => {
    delay == null 
      setDelay(
        heroSliderItems?.[carouselIndex + 1]?.interval ||
        heroSliderItems?.[0]?.interval ||
        null
      )
  }, [delay])

  if (heroSliderItems)
    return (
      <StyledBox mb={{ xs: 3, sm: 6 }} style={{ cursor: 'pointer'}} onClick={() => {
        setDelay(null)
        onSlideSwitch()}}
      >
        {heroSliderItems?.map((item, index) => (
          <StyledBox
            display={index !== carouselIndex && 'none'}
            key={item?._key}
          >
            <Box position="absolute" top="0" left="0" zIndex="50">
              <Box
                px={{ xs: 3, sm: 5 }}
                pt={{ xs: 3, sm: 5 }}
                pb={5}
                width={'55rem'}
                maxWidth={'80vw'}
              >
                <Typography
                  variant="h2"
                  component="h1"
                  style={{ color: item?.color || theme.colors.blue }}
                >
                  {item?.titleOne}
                </Typography>
              </Box>
              <Box px={{ xs: 3, sm: 5 }} width={'55rem'} maxWidth={'90vw'}>
                <Typography
                  variant="h2"
                  component="h1"
                  style={{ color: item?.color || theme.colors.blue }}
                >
                  {item?.titleTwo}
                </Typography>
              </Box>
            </Box>
            <SanityImage
              image={
                width && width < 1025
                  ? item?.imageMobile || item?.imageDesktop
                  : item?.imageDesktop
              }
              altText={''}
              width={2048}
            ></SanityImage>
          </StyledBox>
        ))}
      </StyledBox>
    )
  return null
}

export default HeroSlider

export const query = graphql`
  fragment moduleHeroSliderData on SanityModuleHeroSlider {
    _key
    _type
    heroSliderItems {
      imageDesktop {
        ...Image
      }
      imageMobile {
        ...Image
      }
      titleOne
      titleTwo
      color
      interval
    }
  }
`
