import React, { FC, useContext } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Box, Typography } from '@material-ui/core'
import {
  Maybe,
  SanityModuleFurtherReading,
  SanityPostOrStore
} from 'web/graphql-types'
import { Link } from '@/atoms/link'
import { getUri } from '@/utils/routing'
import { StoresContext } from '@/contexts/StoresContext'

export const Anchor = styled(Link)`
  color: white;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
`
export const Image = styled.div`
  height: 0;
  padding-bottom: 120%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface FurtherReadingProps extends SanityModuleFurtherReading {
  siteType?: 'store' | 'blog'
}

const FurtherReadingModule: FC<FurtherReadingProps> = props => {
  const { title, featuredPost, siteType } = props
  const { setActiveTags } = useContext(StoresContext)

  return (
    <Box
      px={{ xs: 3, sm: siteType === 'store' ? 0 : 5 }}
      mb={{ xs: 1.5, sm: 3 }}
    >
      {title && (
        <Box pb={2}>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
        </Box>
      )}
      <Box
        display="flex"
        alignContent="flex-start"
        flexDirection="row"
        flexWrap={'wrap'}
        margin={-1}
      >
        {featuredPost?.map((postOrStore: Maybe<SanityPostOrStore>) => {
          return (
            <Box
              flexBasis={{
                xs: '50%',
                sm: siteType === 'store' ? '50%' : '25%'
              }}
              px={1}
              onClick={() => { 
                setActiveTags([])
              }}
              key={postOrStore?.id}
            >
              <Anchor
                to={getUri(postOrStore?.slug?.current, postOrStore?._type)}
              >
                <Image
                  style={{
                    backgroundImage: `url(${postOrStore?.meta?.ogImage?.asset
                      ?.url || ''})`
                  }}
                />
                <Box pt={1} pb={{ xs: 2, sm: 0 }}>
                  <Typography align="center" component="h3" variant="h5">
                    {postOrStore?.title}
                  </Typography>
                </Box>
              </Anchor>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default FurtherReadingModule

export const query = graphql`
  fragment moduleFurtherReadingData on SanityModuleFurtherReading {
    _key
    _type
    title
    featuredPost {
      ... on SanityPost {
        id
        title
        _type
        slug {
          current
        }
        meta {
          ogImage {
            asset {
              altText
              url
            }
          }
        }
      }
      ... on SanityStore {
        id
        title
        _type
        slug {
          current
        }
        meta {
          ogImage {
            asset {
              url
              altText
            }
          }
        }
      }
    }
  }
`
