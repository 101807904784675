import React, { FC } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SanityImage from '@/atoms/SanityImage'
import { SanityModuleHero } from 'web/graphql-types'
import { Typography, Box } from '@material-ui/core'

// use Typography component for this title (subtitle2 / blog title )

const Hero = styled(Box)`
  width: 100%;
  height: calc(100vh - 2rem);
  @media screen and (max-width: 1024px) {
    height: calc(100vh - 3rem);
  }
  position: relative;
  img[data-loading] {
    bottom: -20px;
  }
`

const HeroTitleContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 88%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const ImageModule: FC<SanityModuleHero> = ({
  heroImage,
  heroTitle,
  ...props
}) => (
  <Hero mb={{xs: 3, sm: 6}}>
    <HeroTitleContainer>
      <Typography component="h1" variant="subtitle2">
        {heroTitle}
      </Typography>
    </HeroTitleContainer>

    <SanityImage image={heroImage} width={2048}></SanityImage>
  </Hero>
)

export default ImageModule

export const query = graphql`
  fragment moduleHeroData on SanityModuleHero {
    _key
    _type
    heroImage {
      ...Image
    }
    heroTitle
  }
`
