import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { Box } from '@material-ui/core'

type SpacerModule = {
    spacingMobile: number,
    spacingDesktop: number,
}

export type SpacerProps = SpacerModule

const SpacerModule: FC<SpacerProps> = props => {
    const { spacingDesktop, spacingMobile } = props
    return (
        <Box height={{ xs: `${spacingMobile}rem`, sm: `${spacingDesktop}rem` }} width="100%"></Box>
    )
}

export default SpacerModule

export const query = graphql`
  fragment moduleSpacerData on SanityModuleSpacer {
    spacingMobile
    spacingDesktop
  }
`
