import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  max-width: 92rem;
  transform: rotate(-4deg);
  padding: 0 3rem;
  ${p => p.slant && `transform: rotate(4deg);`}
  p {
    // padding-bottom: 0;
  }
`

type SlantedTextModule = {
  slantedText: string
  slant: boolean
}

export type SlantedTextProps = SlantedTextModule

const SlantedTextModule: FC<SlantedTextProps> = props => {
  const { slantedText, slant } = props
  return (
    <StyledBox my={6} mx={'auto'} slant={slant}>
      <Typography component="p" variant="h3">
        {slantedText}
      </Typography>
    </StyledBox>
  )
}

export default SlantedTextModule

export const query = graphql`
  fragment moduleSlantedTextData on SanityModuleSlantedText {
    slantedText
    slant
  }
`
