import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { Box, Typography } from '@material-ui/core'
import SanityImage from '@/atoms/SanityImage'
import { SanityModuleImage } from 'web/graphql-types'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  @media screen and (min-width: 1025px) {
    max-width: 96rem;
  }

  p {
    padding-bottom: 0 !important;

    @media screen and (max-width: 1025px) {
      margin-bottom: -2rem;
    }
  }
`

const ImageModule: FC<SanityModuleImage> = ({ image, ...props }) =>
  image ? (
    <StyledBox
      p={{ xs: '0 3rem', sm: '0 3rem' }}
      mb={{ xs: 5, sm: 3.5 }}
      mx="auto"
      flexDirection="column"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <SanityImage
        image={image}
        altText={image.asset?.altText || image.subtitle || ''}
        objectFit="contain"
        width={1200}
        maxHeight={{ xs: 'unset', sm: '700px' }}
      />
      {image.subtitleAsDownloadLink ?
        image.subtitle && (
          <Box maxWidth="72rem" pt={1.5}>
            <a href={`${image.asset?.url}/${image.asset?.originalFilename}`} download target="_blank">
              <Typography variant="body2" align="center">
                {image.subtitle}
              </Typography>
            </a>
          </Box>
        ) :
        image.subtitle && (
          <Box maxWidth="72rem" pt={1.5}>
            <Typography variant="body2" align="center">
              {image.subtitle}
            </Typography>
          </Box>
        )}
    </StyledBox>
  ) : null

export default ImageModule

export const query = graphql`
  fragment moduleImageData on SanityModuleImage {
    image {
      ...imageWithAlt
    }
  }
`

/*

import styled from 'styled-components'

export const ImageContainer = styled.div`
  text-align: center;
`
export const Image = styled.img`
  max-width: 100%;
  max-height: 70rem;
`
*/
